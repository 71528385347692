/* eslint-disable max-len */
import React from 'react'
import T from 'prop-types'
import { graphql,navigate } from 'gatsby'

import recipesPoster from '../../assets/videoes/recipes.jpg'
import recipesWEBM from '../../assets/videoes/recipes.webm'
import recipesMP4 from '../../assets/videoes/recipes.mp4'
import SEO from '../../components/SEO'
import Hero from '../../components/Hero'
import Entries from '../../components/Entries'
import Section from '../../components/Section'
import { Redirect } from '@reach/router';

const RedirectPage = () => (
  <Redirect from="/gavekart" to="" noThrow />
);
export default RedirectPage;
// export default function Page({ data: { recipesImage, coursesImage, behind } }) {
//   return (
//     <>
//       <SEO title="Gavekort" description="Gi bort kunnskap og matglede" />
//       <Hero
//         title="Gi bort kunnskap og matglede"
//         video={{
//           poster: recipesPoster,
//           webm: recipesWEBM,
//           mp4: recipesMP4,
//         }}
//         actions={[
//           { label: 'Kjøp gavekort', to: '/gavekort/kjop' },
//           { label: 'Løs inn gavekort', to: '/gavekort/los-inn' },
//         ]}
//       />
//       <Section title="Dette er Foodsteps" type="center" />
//       <Entries
//         items={[
//           {
//             title:
//               'Stegvise oppskrifter med videoer som gjør det lett å lage god mat.',
//             to: '/oppskrifter',
//             label: 'Mer om oppskrifter',
//             image: recipesImage.image,
//           },
//           {
//             title:
//               'Korte videokurs med kunnskap og tips som gjør deg tryggere på kjøkkenet.',
//             to: '/kurs',
//             label: 'Mer om kurs',
//             image: coursesImage.image,
//           },
//         ]}
//       />
//       <Hero
//         title="Ekte fagkunnskap"
//         preamble="Foodsteps er laget av de prisvinnende kokkene fra Kulinarisk Akademi. Oppskriftene og kursene passer for både nybegynnere og de som er drevne på kjøkkenet."
//         image={behind.image}
//         type={['uppercase']}
//         aspectRatio={3 / 4}
//         section
//       />
//     </>
//   )
// }
// Page.propTypes = {
//   data: T.object.isRequired,
// }

export const query = graphql`
  query {
    recipesImage: file(relativePath: { eq: "nuddel-biff.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 620) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    coursesImage: file(relativePath: { eq: "matpakkebrod.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 620) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    behind: file(relativePath: { eq: "behind-the-scenes.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 630) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
